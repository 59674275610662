@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Nunito:wght@500&display=swap');

/* content 共通
--------------------------------------------------------------------*/
/*sub page*/
h1.ch1 {
    display:block;
    width:900px;
    margin:0 auto;
    color:#000000;
    font-size:50px;
    font-family: 'Inter', sans-serif;
    padding:10px 0 10px 0;
    font-weight:700;
    span {
        margin: 0 0 20px 0;
    }
}
@media screen and (max-width: 919px){
	h1.ch1 {
		width:auto;
		padding:10px 10px 10px 10px;
		font-size:40px;
	}
}
//Works
h1.w_ch1{
    span {
	    border-bottom:solid 4px #CCCC00;
	    font-weight:700;
    }
}
//Art
h1.i_ch1{
    span {
	    border-bottom:solid 4px #CC99CC;
	    font-weight:700;
    }
} 

/* itemlist
--------------------------------------------------------------------*/
section.itemlist {
    div {
        width:900px;
        margin:0 auto;
        h2 {
            font-size:32px;
            text-align:left;
            line-height:2;
            color:#79C8C2;
            font-family: 'Nunito', sans-serif;
            font-weight:400;
        }
        ul {
            margin: 0 0 30px 0;
            display: block;
            li {
                margin: 0 0 0 0;
                display: -moz-inline-box;
	            display: inline-block;
                a {
                    display: block;
                    position:relative;
                    width:150px;
	                height:150px;
                    border: 2px solid #79C8C2;
                    margin: 20px 20px 0 0;
                    img {
                        //width:140px;
                        //height: 140px;
                        -webkit-filter: grayscale(100%);
                        -moz-filter: grayscale(100%);
                        -ms-filter: grayscale(100%);
                        -o-filter: grayscale(100%);
                        filter: grayscale(100%);
                    }
                    &:hover {
                        border: 2px solid pink;
                        img {
                            //width: 150px;
                            //height: 150px;
                            -webkit-filter: grayscale(0);
                            -moz-filter: grayscale(0);
                            -ms-filter: grayscale(0);
                            -o-filter: grayscale(0);
                            filter: grayscale(0);
                        }
                    }
                    &:active{
                        border: 2px solid pink;
                        img {
                            //width: 150px;
                            //height: 150px;
                            -webkit-filter: grayscale(0);
                            -moz-filter: grayscale(0);
                            -ms-filter: grayscale(0);
                            -o-filter: grayscale(0);
                            filter: grayscale(0);
                        }
                    }
                }
            }
            li.none_right{
                a{
                    margin: 20px 0 0 0;
                }
            } 
        }
    }
}

@media screen and (max-width: 919px){
    section.itemlist {
        div {
            padding: 0 0 0 20px;
            width: auto;
            ul{
                li.none_right{
                    a{
                    margin: 20px 20px 0 0;
                    }
                }
            }
        }
    } 
}