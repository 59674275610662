@charset "utf-8";

/* margin,padding
--------------------------------------------------------------------*/

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
div,
p,
pre,
address,
blockquote,
ul,
ol,
dl,
dt,
dd,
form,
fieldset {
	display: block;
	margin: 0;
	padding: 0;
}


/* font 
--------------------------------------------------------------------*/

body {
	font-size: 10px;
	_font-size: 62.5%;
	*font-size: 62.5%;
	font-size: 62.5%;
}

address,
em {
	font-style: normal;
}

/* list 
--------------------------------------------------------------------*/

ol,
ul {
	list-style-type: none;
}

/* img 
--------------------------------------------------------------------*/

img {
	border: none;
	vertical-align: bottom;
}

/* form
--------------------------------------------------------------------*/

input,
select,
textarea,
button {
	font-size: inherit;
	line-height: inherit;
}

button {
	cursor: pointer;
}

/* table
--------------------------------------------------------------------*/

table,
thead,
tbody,
tfoot,
tr,
th,
td,
caption,
col,
colgroup {
	text-align: inherit;
	line-height: inherit;
	font-size: 100%;
}

table {
	display: table;
}

thead {
	display: table-header-group;
}

tbody {
	display: table-row-group;
}

tfoot {
	display: table-footer-group;
}

tr {
	display: table-row;
}

th,
td {
	display: table-cell;
}

caption {
	display: table-caption;
}

col {
	display: table-column;
}

colgroup {
	display: table-column-group;
}

/* clearfix
--------------------------------------------------------------------*/

.clearfix:after {
    content: "."; 
    display: block; 
    height: 0; 
    clear: both; 
    visibility: hidden;
}

.clearfix {display: inline-block;}/*IE7用*/

/* Hides from IE-mac \*/
* html .clearfix { height:1%;}
.clearfix { display:block;}
/* End hide from IE-mac */