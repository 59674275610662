@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Nunito:wght@500&display=swap');

/* content
--------------------------------------------------------------------*/
/*sub page*/
h1.ch1 {
    display:block;
    width:900px;
    margin:0 auto;
    color:#000000;
    font-size:50px;
    font-family: 'Inter', sans-serif;
    padding:10px 0 10px 0;
    font-weight:700;
}
h1.p_ch1 span {
    border-bottom:solid 4px #3399FF;
}
@media screen and (max-width: 919px){
	h1.ch1 {
		width:auto;
		padding:10px 10px 10px 10px;
		font-size:40px;
	}
}


/*	prof
---------------------------------------------------------------------*/
section.prof {
	padding:0 0 20px 0;
    div {
        width:900px;
        margin:0 auto;
        h2 {
            font-size:32px;
            text-align:center;
            line-height:2;
            color:#79C8C2;
            margin:0 0 20px 0;
            font-family: 'Nunito', sans-serif;
            font-weight:400;
        }
        dl{
            dt {
                text-align:center;
                margin:0 0 15px 0;
            }
            dd {
                font-size:20px;
                font-family: 'Nunito', sans-serif;
                margin-bottom:20px;
                line-height:1.4;
                span {
                    font-size:12px;
                }
            }
        }
        ul {
            text-align:center;
            li {
                display:inline-block;
                padding:15px 5px 5px 5px;
                span {
                    background:#EEEEEE;
                    border-radius:5px 5px 5px 5px / 5px 5px 5px 5px;
                    padding:5px 5px 5px 5px;
                    color:#000000;
                    font-size:16px;
                    font-family: 'Nunito', sans-serif;
                }
            }
        }
        
    }
}

@media screen and (max-width: 919px){
	section.prof div {
		width:auto;
		padding:0 20px 0 20px;
	}
}
@media screen and (max-width: 219px){
	section.prof div dl dt img {
		width:100%;
	}
}

/*	profbox
---------------------------------------------------------------------*/
section.profbox {
	padding:20px 0 20px 0;
    div {
        width:900px;
        margin:0 auto;
        h3 {
            text-align:center;
            line-height:2;
            color:#FFFFFF;
            text-align:center;
            margin:0 0 20px 0;
            font-family: 'Nunito', sans-serif;
            text-shadow: 1px 1px 2px #333366;
            font-size:28px;
            font-weight:400;
        }
        p {
            font-size:20px;
            font-family: 'Nunito', sans-serif;
            padding:0 0 20px 0;
            color:#EEEEEE;
            text-shadow: 1px 1px 2px #333366;
        }
    }
}
@media screen and (max-width: 919px){
	section.profbox div {
		width:auto;
		padding:0 20px 0 20px;
        h3 {
            line-height:1.4;
        }
	}
}


/*	education
---------------------------------------------------------------------*/
section.education {
	background:#3399FF;
}


/*	work
---------------------------------------------------------------------*/
section.work {
	background:#CCCC00;
}


/*	philippines
---------------------------------------------------------------------*/
section.philippines {
	background:#FF99CC;
}

/*	Taiwan
---------------------------------------------------------------------*/
section.taiwan {
	background:#FF9900;
}

/*	learn
---------------------------------------------------------------------*/
section.learn {
	background:#2e9997;
}